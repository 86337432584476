<template>
  <section id="custom-default-registration-container">
    <b-container fluid>
      <b-row>
        <TabHeaderInfo
          title="Registros padrões"
          tooltip="Modelos de consulta com os campos e informações que desejar."
          :tooltipId="'Registros padrões'.split(' ')[0]"
        />
      </b-row>
      <b-row class="cards">
        <b-col
          v-for="standard in standards"
          :key="standard.id"
          md="4"
          sm="12"
          class="card"
        >
          <FormCard
            :link="`/${standard.link}/${standard.id}`"
            :form="standard"
            @reloadForms="getStandards"
          />
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { getCurrentUser } from '@/utils/localStorageManager'

export default {
  name: 'my-record-tab',
  async created() {
    await this.getStandards()
  },
  data() {
    return {
      user: getCurrentUser(),
      standards: []
    }
  },
  props: {},
  components: {
    FormCard: () => import('@/components/Config/Appointment/FormCardRecord'),
    TabHeaderInfo: () => import('@/components/General/TabHeaderInfo')
  },
  methods: {
    async getStandards() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getStandardsRecordByPerson(this.user.id)
        this.standards = data.map(std => {
          return { ...std, ...this.getExtraInfo(std.type) }
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    getExtraInfo(type) {
      switch (type) {
        case 'biomicroscopia':
          return {
            name: 'Biomicroscopia',
            description:
              'Registros padrões nos campos de biomicroscopia como cílios e pálpebras, conjuntiva, córnea, etc.',
            link: 'registro-padrao'
          }
        case 'mapeamento-de-retina':
          return {
            name: 'Mapeamento de retina/fundoscopia',
            description:
              'Registros padrões nos campos de mapeamento de retina como vítreo, disco óptico, escavação, mácula, etc.',
            link: 'registro-padrao'
          }
        case 'oculos':
          return {
            name: 'Prescrição de óculos',
            description:
              'Registros padrões nos campos de prescrição de óculos como modelo de prescrição, mais informações, etc.',
            link: 'registro-padrao/oculos'
          }
        case 'biometria':
          return {
            name: 'Biometria',
            description:
              'Registros padrões nos campos da biometria como “AL”, “ACD”, etc.',
            link: 'registro-padrao/biometria'
          }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#custom-default-registration-container {
  .pt-container {
    padding: 30px 10px;
    .pt-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 30px 0;
  
      .pt-title {
        display: inline-flex;
        align-items: center;
        font-family: 'Red Hat Display';
        font-weight: 500;
        font-size: 18px;
      }
  
      .icon {
        height: 24px;
        width: 24px;
        margin-left: 5px;
        stroke: var(--neutral-500);
      }
    }
  
    .flex-standards {
      margin-top: 2%;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
  
      @media (max-width: 1000px) {
        flex-direction: column;
  
      }
    }
  }

  .form-card {
    width: 100%;
    text-align: left;
    height: 100%;
    background: var(--neutral-000);
    border-radius: 8px;
    padding: 1rem;
    border: none;
    margin: 0;
  }
  
  .cards {
    display: flex;
    gap: 1rem;
    border-radius: 8px !important;
    .card {
      border: 1px solid var(--neutral-200);
      border-radius: 8px;
      padding: 0 !important;
      max-width: calc((100% - (1rem * 3)) / 4);
    }
  }
}
</style>
